export enum AssetEventType {
  AKTIVALAS = 'AKTIVALAS',

  ERTEKCSOKKENES = 'ERTEKCSOKKENES',

  TERVEN_FELULI_ECS = 'TERVEN_FELULI_ECS',

  ERTEKHELYESBITES = 'ERTEKHELYESBITES',

  SELEJTEZES = 'SELEJTEZES',

  ERTEKESITES = 'ERTEKESITES',

  ATVEZETES = 'ATVEZETES',

  ESZKOZTIPUS_ATVEZ = 'ESZKOZTIPUS_ATVEZ',

  LEVALASZTAS = 'LEVALASZTAS'
}
