import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'app/shared/globals/globals.service';

@Pipe({
  name: 'translateSpecification',
})
export class TranslateSpecificationPipe implements PipeTransform {
  constructor(
    protected translateService: TranslateService,
    protected globalsService: GlobalsService
    ) {}

    transform(entity: any, translationSpecification?: string, translationCollection?: {[s: string]: string}): string {
      const userLang = this.translateService.currentLang;
      const appLang = this.globalsService.getParam("Language");
      let result: string | undefined | null;
  
      if (entity) {
        const collection = translationCollection 
                           ? typeof translationCollection === "string"
                           ? JSON.parse(translationCollection)
                           : translationCollection
                           : <{[s: string]: string}>(typeof entity.specificationTranslations === "string"
                           ? JSON.parse(entity.specificationTranslations)
                           : entity.specificationTranslations);
        const specification = translationSpecification ? translationSpecification : <string>entity.specification;
  
        if (userLang !== appLang && collection !== null && collection !== undefined && JSON.stringify(collection) !== '{}') {
          result =
            collection[userLang]
            ?? collection['en']
            ?? specification;
        } else {
          result = specification;
        }
      }
      return result ?? '';
    }
  }