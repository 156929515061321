import * as dayjs from 'dayjs';
import { ParameterCategory } from '../enumerations/parameter-category.model';

export interface IParameter {
  id?: number;
  appId?: string;
  paramName?: string;
  paramDescription?: string;
  paramCategory?: ParameterCategory;
  appAdjustable?: boolean | null;
  paramValue?: string;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
}

export class Parameter implements IParameter {
  constructor(
    public id?: number,
    public appId?: string,
    public paramName?: string,
    public paramDescription?: string,
    public paramCategory?: ParameterCategory,
    public appAdjustable?: boolean | null,
    public paramValue?: string,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null
  ) {
    this.appAdjustable = this.appAdjustable ?? false;
  }
}

export function getParameterIdentifier(parameter: IParameter): number | undefined {
  return parameter.id;
}
