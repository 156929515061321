<ng-template #dialogContainer></ng-template>

<div class="row mt-5">
  <div class="col-md-3">
    <span class="hipster img-fluid rounded"></span>
  </div>

  <div class="col-md-9">
    <h1 class="display-4" authTranslate="home.title">Magus welcomes you!</h1>

    <p class="lead ms-3 mt-2 mb-4 fst-italic" authTranslate="home.subtitle">This is your homepage</p>

    <div [ngSwitch]="isAuthenticated()">
      <div class="alert alert-success" *ngSwitchCase="true">
        <span id="home-logged-message" *ngIf="account" authTranslate="home.logged.message" [translateValues]="{ username: account.login }"
          >You are logged in as user "{{ account.login }}".</span
        >
      </div>

      <div class="alert alert-warning" *ngSwitchCase="false">
        <span authTranslate="global.messages.info.authenticated.prefix">If you want to </span>
        <a class="alert-link" (click)="login()" authTranslate="global.messages.info.authenticated.link">sign in</a>
        <!-- <span authTranslate="global.messages.info.authenticated.suffix">, you can try the default account:- User (login="user" and password="user").</span> -->
      </div>

      <div class="alert alert-warning" *ngSwitchCase="false">
        <span authTranslate="global.messages.info.register.noaccount">You don't have an account yet?</span>&nbsp;
        <a class="alert-link" routerLink="account/register" authTranslate="global.messages.info.register.link">Register a new account</a>
      </div>

      <div *ngSwitchCase="true" class="mt-5">
        <div *ngIf="getApps() > 1" class="ui-g-7">
          <p-carousel [value]="homeService.apps!" [numVisible]="4" [numScroll]="1" [responsiveOptions]="responsiveOptions">
            <ng-template let-app pTemplate="item">
              <div style="text-align: center">
                <div>
                  <a><img [src]="app.safeUrl" style="height: 7em;" (click)="setApplicationID(app.id)"/></a>
                  <!-- <a><img src="../../content/images/logo_1.png" (click)="setApplicationID(app.id)" /></a> -->
                </div>
                <div>
                  <div style="padding-top: 1.5em">
                    <a (click)="setApplicationID(app.id)">{{ app.appFullName }}</a>
                  </div>
                </div>
              </div>
            </ng-template>
          </p-carousel>
        </div>
        <!-- <div>
          <p *ngIf="getApplicationID() !== ''" authTranslate="home.selectedApplication" [translateValues]="{ appName: application?.appFullName }">A kiválasztott alkalmazás</p>
        </div> -->
      </div>
    </div>
  </div>
</div>
