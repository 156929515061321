import { Pipe, PipeTransform } from '@angular/core';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { DecimalPipe } from '@angular/common'

@Pipe({
  name: 'formatNumber',
})
export class FormatNumberPipe implements PipeTransform {
  constructor(
    protected globalsService: GlobalsService,
    private decimalPipe: DecimalPipe
    ) {}

  transform(transform: string | number, decimals = "1.2-2"): number | string | null {
    const numberFormat = this.globalsService.getParam("NumberFormat");
    let res = null;

    if (typeof transform === 'string') {
      if (numberFormat === 'hu') {
        res = transform.replace(/\s/g,"").replace(",",".");
        res = Number(res);
      } else {
        res = +transform.replace(/,/g,'');
      }
    } else {
      res = this.decimalPipe.transform(transform,decimals,numberFormat);
    }
    return res;
  }
}
