import * as dayjs from 'dayjs';

export interface IMagJsonParam {
  id?: number;
  userID?: string;
  entityID?: string;
  entityType?: string | null;
  jsonContent?: string | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
}

export class MagJsonParam implements IMagJsonParam {
  constructor(
    public id?: number,
    public userID?: string,
    public entityID?: string,
    public entityType?: string | null,
    public jsonContent?: string | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null
  ) {}
}

export function getMagJsonParamIdentifier(magJsonParam: IMagJsonParam): number | undefined {
  return magJsonParam.id;
}
