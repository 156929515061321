import { Component, Input} from '@angular/core';
import { DataSharingService } from 'app/shared/services/data-sharing.service';

@Component({
  selector: 'auth-galleria-full',
  templateUrl: './galleria-full.component.html',
})
export class GalleriaFullComponent {
  @Input() images: any[] = [];
  @Input() displayGalleria = false;
  
  responsiveOptions: any[] = [
    {
        breakpoint: '1500px',
        numVisible: 5
    },
    {
        breakpoint: '1024px',
        numVisible: 3
    },
    {
        breakpoint: '768px',
        numVisible: 2
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
  ];

  constructor(
    public dataSharingService: DataSharingService,
  ) {}

  closeGalleria(): void {
    this.displayGalleria = false;
    this.dataSharingService.visibleGalleria.next(false);
  }
}
