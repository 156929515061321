import * as dayjs from 'dayjs';
import { IUser } from 'app/entities/user/user.model';
import { SafeUrl } from '@angular/platform-browser';

export interface IApplication {
  id?: string;
  appShortName?: string;
  appFullName?: string;
  logo?: string | null;
  logoContentType?: string | null;
  safeUrl?: SafeUrl;
  jsonContent?: Map<string,any> | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
  users?: IUser[] | null;
}

export class Application implements IApplication {
  constructor(
    public id?: string,
    public appShortName?: string,
    public appFullName?: string,
    public logo?: string | null,
    public logoContentType?: string | null,
    public safeUrl?: SafeUrl,
    public jsonContent?: Map<string,any> | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null,
    public users?: IUser[] | null
  ) {}
}

export function getApplicationIdentifier(application: IApplication): string | undefined {
  return application.id;
}
