import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'app/shared/globals/globals.service';

@Pipe({
  name: 'translateName',
})
export class TranslateNamePipe implements PipeTransform {
  constructor(
    protected translateService: TranslateService,
    protected globalsService: GlobalsService
    ) {}

  transform(entity: any, translationName?: string, translationCollection?: {[s: string]: string}): string {
    const userLang = this.translateService.currentLang;
    const appLang = this.globalsService.getParam("Language");
    let result: string | undefined | null;

    if (entity) {
      const collection = translationCollection 
                         ? typeof translationCollection === "string"
                         ? JSON.parse(translationCollection)
                         : translationCollection
                         : <{[s: string]: string}>(typeof entity.nameTranslations === "string"
                         ? JSON.parse(entity.nameTranslations)
                         : entity.nameTranslations);
      const name = translationName ? translationName : <string>entity.name;

      if (userLang !== appLang && collection !== null && collection !== undefined && JSON.stringify(collection) !== '{}') {
        result =
          collection[userLang]
          ?? collection['en']
          ?? name;
      } else {
        result = name;
      }
    }
    return result ?? '';
  }
}
