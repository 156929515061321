import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { GlobalsService } from 'app/shared/globals/globals.service';
// import { FormatHtmlTextPipe } from './format-html-text.pipe';

@Pipe({
  name: 'translateComment',
})
export class TranslateCommentPipe implements PipeTransform {
  constructor(
    protected translateService: TranslateService,
    protected globalsService: GlobalsService,
    // private formatHtmlTextPipe: FormatHtmlTextPipe
    ) {}

  transform(entity: any, lang?: string): string {
console.log(lang);
    const userLang = this.translateService.currentLang;
    const appLang = this.globalsService.getParam("Language");
    let result: string | undefined | null;

    if (lang === undefined) {
      lang = userLang;
    }

    if (entity) {
      if (lang !== appLang || entity.commentTranslations === null || entity.commentTranslations === undefined || entity.commentTranslations === new Object) {
        result =
          new Map<string,string>(Object.entries(entity!.commentTranslations!)).get(lang!)
          ?? new Map<string,string>(Object.entries(entity!.commentTranslations!)).get('en')
          ?? entity.comment;
      } else {
        result = entity.comment;
      }
    }  
    return result ?? '';
    // return result ? this.formatHtmlTextPipe.transform(result) : '';
  }
}
