import * as dayjs from 'dayjs';
import { IAccessGroup } from 'app/entities/access-group/access-group.model';
import { IAccessFunction } from 'app/entities/access-function/access-function.model';
import { Operation } from 'app/entities/enumerations/operation.model';

export interface IAccessRight {
  id?: number;
  appId?: string;
  operations?: Operation[] | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
  accessGroup?: IAccessGroup | null;
  accessFunction?: IAccessFunction | null;
}

export class AccessRight implements IAccessRight {
  constructor(
    public id?: number,
    public appId?: string,
    public operations?: Operation[] | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null,
    public accessGroup?: IAccessGroup | null,
    public accessFunction?: IAccessFunction | null
  ) {}
}

export function getAccessRightIdentifier(accessRight: IAccessRight): number | undefined {
  return accessRight.id;
}

export interface IAccessRightView {
  id?: number;
  appId?: string;
  accessFunctionId?: number | null;
  accessFunctionName?: string | null;
  accessFunctionDescription?: string | null;
  accessGroupId?: number | null;
  accessGroupName?: string | null;
  accessGroupDescription?: string | null;
  operations?: Operation[] | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
}

export class AccessRightView implements IAccessRightView {
  constructor(
    public id?: number,
    public appId?: string,
    public accessFunctionId?: number | null,
    public accessFunctionName?: string | null,
    public accessFunctionDescription?: string | null,
    public accessGroupId?: number | null,
    public accessGroupName?: string | null,
    public accessGroupDescription?: string | null,
    public operations?: Operation[] | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null
  ) {}
}

export function getAccessRightViewIdentifier(accessRightView: IAccessRightView): number | undefined {
  return accessRightView.id;
}

export interface IAccessRightsByUser {
  functionCode?: string | null;
  operations?: string | null;
}

