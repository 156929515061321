import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IParameter, getParameterIdentifier } from '../parameter.model';
import { GlobalsService } from 'app/shared/globals/globals.service';

export type EntityResponseType = HttpResponse<IParameter>;
export type EntityArrayResponseType = HttpResponse<IParameter[]>;

@Injectable({ providedIn: 'root' })
export class ParameterService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/parameters');

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService, protected globalsService: GlobalsService) {}

  create(parameter: IParameter): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(parameter);
    return this.http
      .post<IParameter>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(parameter: IParameter): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(parameter);
    return this.http
      .put<IParameter>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getParameterIdentifier(parameter) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(parameter: IParameter): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(parameter);
    return this.http
      .patch<IParameter>(`${this.resourceUrl}/${getParameterIdentifier(parameter) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IParameter>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IParameter[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  addParameterToCollectionIfMissing(
    parameterCollection: IParameter[],
    ...parametersToCheck: (IParameter | null | undefined)[]
  ): IParameter[] {
    const parameters: IParameter[] = parametersToCheck.filter(isPresent);
    if (parameters.length > 0) {
      const parameterCollectionIdentifiers = parameterCollection.map(parameterItem => getParameterIdentifier(parameterItem)!);
      const parametersToAdd = parameters.filter(parameterItem => {
        const parameterIdentifier = getParameterIdentifier(parameterItem);
        if (parameterIdentifier == null || parameterCollectionIdentifiers.includes(parameterIdentifier)) {
          return false;
        }
        parameterCollectionIdentifiers.push(parameterIdentifier);
        return true;
      });
      return [...parametersToAdd, ...parameterCollection];
    }
    return parameterCollection;
  }

  protected convertDateFromClient(parameter: IParameter): IParameter {
    return Object.assign({}, parameter, {
      createdDate: parameter.createdDate?.isValid() ? parameter.createdDate.toJSON() : undefined,
      lastModifiedDate: parameter.lastModifiedDate?.isValid() ? parameter.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((parameter: IParameter) => {
        parameter.createdDate = parameter.createdDate ? dayjs(parameter.createdDate) : undefined;
        parameter.lastModifiedDate = parameter.lastModifiedDate ? dayjs(parameter.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
