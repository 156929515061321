import * as dayjs from 'dayjs';
import { IIntrastatReportLine } from './intrastat-report-lines/intrastat-report-line.model';
import { IntrastatReportType } from 'app/entities/enumerations/intrastat-report-type.model';

export interface IIntrastatReport {
  id?: number;
  appId?: string;
  intrastatReportNumber?: string | null;
  intrastatReportDate?: dayjs.Dayjs | null;
  intrastatYearMonth?: number | null;
  intrastatReportType?: IntrastatReportType | null;
  fillUpTime?: number | null;
  approverName?: string | null;
  approverJobTitle?: string | null;
  approverEmail?: string | null;
  approverPhone?: string | null;
  comletingPersonName?: string | null;
  comletingPersonJobTitle?: string | null;
  comletingPersonEmail?: string | null;
  comletingPersonPhone?: string | null;
  spareNumber1?: number | null;
  spareNumber2?: number | null;
  spareNumber3?: number | null;
  spareDate1?: dayjs.Dayjs | null;
  spareDate2?: dayjs.Dayjs | null;
  spareString1?: string | null;
  spareString2?: string | null;
  spareString3?: string | null;
  comment?: string | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  intrastatReportLines?: IIntrastatReportLine[] | null;
}

export class IntrastatReport implements IIntrastatReport {
  constructor(
    public id?: number,
    public appId?: string,
    public intrastatReportNumber?: string | null,
    public intrastatReportDate?: dayjs.Dayjs | null,
    public intrastatYearMonth?: number | null,
    public intrastatReportType?: IntrastatReportType | null,
    public fillUpTime?: number | null,
    public approverName?: string | null,
    public approverJobTitle?: string | null,
    public approverEmail?: string | null,
    public approverPhone?: string | null,
    public comletingPersonName?: string | null,
    public comletingPersonJobTitle?: string | null,
    public comletingPersonEmail?: string | null,
    public comletingPersonPhone?: string | null,
    public spareNumber1?: number | null,
    public spareNumber2?: number | null,
    public spareNumber3?: number | null,
    public spareDate1?: dayjs.Dayjs | null,
    public spareDate2?: dayjs.Dayjs | null,
    public spareString1?: string | null,
    public spareString2?: string | null,
    public spareString3?: string | null,
    public comment?: string | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public intrastatReportLines?: IIntrastatReportLine[] | null
  ) {}
}

export function getIntrastatReportIdentifier(intrastatReport: IIntrastatReport): number | undefined {
  return intrastatReport.id;
}
