import { HttpParams } from '@angular/common/http';
import { IProductCode } from 'app/entities/product/product.model';
import { IColumnFilter } from 'app/shared/interfaces/column-filter/column-filter.model';

export const createRequestOption = (req?: any): HttpParams => {
  let options: HttpParams = new HttpParams();
  let values: string;

  if (req) {
    Object.keys(req).forEach(key => {
      if (key !== 'sort' && key !== 'tableFilter' && key !== 'productCodes') {
        options = options.set(key, req[key]);
      }
    });

    if (req.sort) {
      req.sort.forEach((val: string) => {
        options = options.append('sort', val);
      });
    }

    if (req.tableFilter) {
      req.tableFilter.forEach((val: IColumnFilter) => {
        if (val.values) {
          values = '';
          for (let x = 0; x < val.values.length; x++) {
            values += val.values[x];
            if (x + 1 < val.values.length) {
              values += val.matchMode === 'in' || val.matchMode === 'notIn' ? ',' : '|';
            }
          }
          options = options.append(val.column! + '.' + val.matchMode!, values);
        }
      });
    }

    if (req.productCodes) {
      req.productCodes.forEach((val: IProductCode, index: number) => {
        const keys = Object.keys(val);
        const vals = Object.values(val);

        for (let i = 0; i < keys.length; i++) {
          options = options.append(`productCodes[${index}].${keys[i]}`, vals[i]);
        }
      });
    }
  }

  return options;
};
