import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IAccessRight, IAccessRightView, getAccessRightIdentifier, IAccessRightsByUser } from '../access-right.model';
import { GlobalsService } from 'app/shared/globals/globals.service';

export type EntityResponseType = HttpResponse<IAccessRight>;
export type EntityArrayResponseType = HttpResponse<IAccessRight[]>;
export type EntityArrayResponseTypeView = HttpResponse<IAccessRightView[]>;

@Injectable({ providedIn: 'root' })
export class AccessRightService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/access-rights');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService, protected globalsService: GlobalsService) {}

  create(accessRight: IAccessRight): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(accessRight);
    return this.http
      .post<IAccessRight>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(accessRight: IAccessRight): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(accessRight);
    return this.http
      .put<IAccessRight>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getAccessRightIdentifier(accessRight) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(accessRight: IAccessRight): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(accessRight);
    return this.http
      .patch<IAccessRight>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getAccessRightIdentifier(accessRight) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IAccessRight>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  getAccessRightsByUser(login: string): Observable<HttpResponse<IAccessRightsByUser[]>> {
    return this.http
      .get<IAccessRightsByUser[]>(`${this.resourceUrl}/find/${this.globalsService.getParam('AppId')}&${login}`, { observe: 'response' });
  }

  hasFullRights(login: string): Observable<HttpResponse<boolean>> {
    return this.http
      .get<boolean>(`${this.resourceUrl}/has-full-rights/${this.globalsService.getParam('AppId')}&${login}`, { observe: 'response' });
  }

  query(req?: any): Observable<EntityArrayResponseTypeView> {
    const options = createRequestOption(req);
    return this.http
      .get<IAccessRight[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseTypeView) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  addAccessRightToCollectionIfMissing(
    accessRightCollection: IAccessRight[],
    ...accessRightsToCheck: (IAccessRight | null | undefined)[]
  ): IAccessRight[] {
    const accessRights: IAccessRight[] = accessRightsToCheck.filter(isPresent);
    if (accessRights.length > 0) {
      const accessRightCollectionIdentifiers = accessRightCollection.map(accessRightItem => getAccessRightIdentifier(accessRightItem)!);
      const accessRightsToAdd = accessRights.filter(accessRightItem => {
        const accessRightIdentifier = getAccessRightIdentifier(accessRightItem);
        if (accessRightIdentifier == null || accessRightCollectionIdentifiers.includes(accessRightIdentifier)) {
          return false;
        }
        accessRightCollectionIdentifiers.push(accessRightIdentifier);
        return true;
      });
      return [...accessRightsToAdd, ...accessRightCollection];
    }
    return accessRightCollection;
  }

  protected convertDateFromClient(accessRight: IAccessRight): IAccessRight {
    return Object.assign({}, accessRight, {
      createdDate: accessRight.createdDate?.isValid() ? accessRight.createdDate.toJSON() : undefined,
      lastModifiedDate: accessRight.lastModifiedDate?.isValid() ? accessRight.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType | EntityArrayResponseTypeView): EntityArrayResponseType | EntityArrayResponseTypeView {
    if (res.body) {
      res.body.forEach((accessRight: IAccessRight | IAccessRightView) => {
        accessRight.createdDate = accessRight.createdDate ? dayjs(accessRight.createdDate) : undefined;
        accessRight.lastModifiedDate = accessRight.lastModifiedDate ? dayjs(accessRight.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
