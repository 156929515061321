<ng-template #dialogContainer></ng-template>

<!-- <p-menubar [model]="items">
  <ng-template pTemplate="start">
      <img src="assets/showcase/images/primeng.svg" height="40" class="p-me-2">
  </ng-template>
  <ng-template pTemplate="end">
      <input type="text" pInputText placeholder="Search">
  </ng-template>
</p-menubar> -->

<p-panelMenu [model]="items" [style]="{'width':'10vw -2rem'}" [multiple]="true"></p-panelMenu>
<!-- <pre>{{ activeItem | json }}</pre> -->