import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DATE_FORMAT } from 'app/config/input.constants';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';
import * as dayjs from 'dayjs';
import { ConfirmationService } from 'primeng/api';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { AlertService } from 'app/core/util/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'app/entities/parameter/service/parameter.service';
import { HttpResponse } from '@angular/common/http';
import { YearMonthService } from 'app/shared/services/year-month.service';
import { IYearMonth } from 'app/shared/interfaces/year-month/year-month.model';
import { IntrastatReportService } from '../service/intrastat-report.service';

@Component({
  selector: 'auth-intrastat-close-month',
  templateUrl: './intrastat-close-month.component.html'
})
export class IntrastatCloseMonthComponent implements OnInit {
  @Output() deleteSelf: EventEmitter<void> = new EventEmitter<void>();
  
  actualPeriod?: string;
  nextPeriod?: string;
  period?: number;

  constructor(
    protected globalsService: GlobalsService,
    protected confirmationService: ConfirmationService,
    protected intrastatReportService: IntrastatReportService,
    protected translateService: TranslateService,
    protected parameterService: ParameterService,
    private alertService: AlertService,
    protected yearMonthService: YearMonthService,
    ) {}

  ngOnInit(): void {
    this.yearMonthService.getYearMonth('INTRASTAT').subscribe((r: HttpResponse<IYearMonth>) => {
      if (r.body) {
        this.period = r.body.yearMonth!;

        const periodYear = Math.trunc(this.period / 100);
        const periodMonth = this.period - (periodYear * 100);

        if (periodYear && periodMonth) {
          this.actualPeriod = this.globalsService.formatYearMonth(this.period);
          if (periodMonth === 12) {
            this.nextPeriod = this.globalsService.formatYearMonth(((periodYear+1) * 100) + 1);
          } else {
            this.nextPeriod = this.globalsService.formatYearMonth((periodYear * 100) + periodMonth +1);
          }
        };
        this.showConfirmDialog();
      }
    });
  }

  showConfirmDialog(): void{
    if (this.actualPeriod && this.nextPeriod) {
      this.confirmationService.confirm({
        message: this.translateService.instant('magusApp.intrastatReport.closing.message', {
          actualPeriod: this.actualPeriod,
          nextPeriod: this.nextPeriod
        }),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translateService.instant('magusApp.intrastatReport.closing.accept'),
        rejectLabel: this.translateService.instant('magusApp.intrastatReport.closing.reject'),
        accept: () => {
          this.intrastatReportService.closing('INTRASTAT',this.period!).subscribe((res) => {
            if (res.length){
              this.showMessages(res)
            }
            this.deleteSelf.emit();
          });
        },
        reject: () => {
          this.deleteSelf.emit();
        },
      });
    }
  }

  showMessages(messages: IBackEndMessage[]): void {
    if(messages.length){
      const mp: {[key: string]: any} = {};
      messages.forEach( m => {
        if (m.messageParam) {
          const keys = Object.keys(m.messageParam);
          const values = Object.values(m.messageParam);
          keys.forEach((k,i) => {
            const v = values[i];
            mp[k] = k.indexOf("date_") < 0 ? v : dayjs(v,DATE_FORMAT).format(this.globalsService.getParam('DateFormat'));
          });
        }
          
        this.alertService.addAlert({
          type: m.messageType === "WARN" ? 'warning' : m.messageType === "ERROR" ? 'danger' : m.messageType === "INFO" ? 'info' : 'success',
          message: this.translateService.instant( "magusApp.backEndMessage."+ m.messageCode!, mp),
          timeout: 10000
        });
      });  
    }
  }
}
