import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DATE_FORMAT } from 'app/config/input.constants';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';
import * as dayjs from 'dayjs';
import { ConfirmationService } from 'primeng/api';
import { GlStatementService } from '../../service/gl-statement.service';
import { GlobalsService } from '../../../../shared/globals/globals.service';
import { AlertService } from 'app/core/util/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ParameterService } from 'app/entities/parameter/service/parameter.service';
import { HttpResponse } from '@angular/common/http';
import { IParameter } from 'app/entities/parameter/parameter.model';

@Component({
  selector: 'auth-gl-statement-close-year',
  templateUrl: './gl-statement-close-year.component.html'
})
export class GlStatementCloseYearComponent implements OnInit {
  @Output() deleteSelf: EventEmitter<void> = new EventEmitter<void>();
  yearToClose?: number;

  constructor(
    protected globalsService: GlobalsService,
    protected confirmationService: ConfirmationService,
    protected glStatementService: GlStatementService,
    protected translateService: TranslateService,
    protected parameterService: ParameterService,
    private alertService: AlertService
    ) {}

  ngOnInit(): void {
    this.getYearToClose();
  }

  getYearToClose(): void{
    this.parameterService
    .query({
      tableFilter: [
        { column: 'paramName', matchMode: 'equals', values: ['FKV_ZARTEV'] },
      ],
    })
    .subscribe((res: HttpResponse<IParameter[]>) => {
      if(res.body?.length && res.body[0].paramValue){
        const lastClosedGlYear = +res.body[0].paramValue;
        this.yearToClose = lastClosedGlYear + 1;
        this.showConfirmDialog();
      }     
    });
  }

  showConfirmDialog(): void{
    this.confirmationService.confirm({
      message: this.translateService.instant('magusApp.glStatement.closeYear.dialogQuestion', {
        yearToClose: this.yearToClose,
      }),
      icon: 'pi pi-exclamation-triangle',
      acceptButtonStyleClass: 'btn btn-primary',
      rejectButtonStyleClass: 'btn btn-secondary',
      accept: () => {
        this.closeGlYear();
        this.deleteSelf.emit();
      },
      reject: () => {
        this.deleteSelf.emit();
      },
    });
  }

  closeGlYear(): void{
    this.glStatementService.closeGlYear(this.yearToClose!).subscribe((res: IBackEndMessage[]) => {
      if (res.length) {
        this.showMessages(res);
      }
    });
  }

  showMessages(messages: IBackEndMessage[]): void {
    if(messages.length){
      const mp: {[key: string]: any} = {};
      messages.forEach( m => {
        if (m.messageParam) {
          const keys = Object.keys(m.messageParam);
          const values = Object.values(m.messageParam);
          keys.forEach((k,i) => {
            const v = values[i];
            mp[k] = k.indexOf("date_") < 0 ? v : dayjs(v,DATE_FORMAT).format(this.globalsService.getParam('DateFormat'));
          });
        }
          
        this.alertService.addAlert({
          type: m.messageType === "WARN" ? 'warning' : m.messageType === "ERROR" ? 'danger' : m.messageType === "INFO" ? 'info' : 'success',
          message: this.translateService.instant( "magusApp.backEndMessage."+ m.messageCode!, mp),
          timeout: 10000
        });
      });  
    }
  }
}
