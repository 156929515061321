import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { IYearMonth } from '../interfaces/year-month/year-month.model';
import { GlobalsService } from '../globals/globals.service';

export type EntityResponseType = HttpResponse<IYearMonth>;

@Injectable({ providedIn: 'root' })
export class YearMonthService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/year-months');

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService, protected globalsService: GlobalsService) {}

  find(type: string, yearMonth: string): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.resourceUrl}/gen/${this.globalsService.getParam("AppId")}&${type}&${yearMonth}`, { observe: 'response' });
  }

  getYearMonth(type: string): Observable<EntityResponseType> {
    return this.http.get<IYearMonth>(`${this.resourceUrl}/${this.globalsService.getParam("AppId")}&${type}`, { observe: 'response' });
  }
}
