<p-dialog [modal]="true" [(visible)]="isVisible" [header]="'login.title' | translate" [visible]="true">
  <div class="row justify-content-center login-dialog-content">
    <div class="col-md-8">
      <div class="alert alert-danger" *ngIf="authenticationError" authTranslate="login.messages.error.authentication">
        <strong>Failed to sign in!</strong> Please check your credentials and try again.
      </div>
    </div>

    <div class="col-md-8" [ngSwitch]="isAuthenticated()">
      <form class="form" role="form" (ngSubmit)="login()" [formGroup]="loginForm">
        <div *ngSwitchCase="false" class="form-group mb-3">
          <label class="form-label" for="username" authTranslate="global.form.username.label">Login</label>
          <input
            type="text"
            class="form-control"
            name="username"
            id="username"
            placeholder="{{ 'global.form.username.placeholder' | translate }}"
            formControlName="username"
            #username
          />
        </div>

        <div *ngSwitchCase="false" class="form-group mb-3">
          <label class="form-label" for="password" authTranslate="login.form.password">Password</label>
          <input
            type="password"
            class="form-control"
            name="password"
            id="password"
            placeholder="{{ 'login.form.password.placeholder' | translate }}"
            formControlName="password"
          />
        </div>

        <div *ngSwitchCase="false" class="form-check mb-3 d-flex justify-content-start">
          <label class="form-label d-flex align-items-center" for="rememberMe">
            <input class="form-check-input" type="checkbox" name="rememberMe" id="rememberMe" formControlName="rememberMe" />
            <span class="mx-3" authTranslate="login.form.rememberme">Remember me</span>
          </label>
        </div>

        <button type="submit" class="btn btn-primary" authTranslate="login.form.button">Sign in</button>
      </form>

      <div *ngSwitchCase="false" class="mt-3 alert alert-warning">
        <a class="alert-link" (click)="requestResetPassword()" authTranslate="login.password.forgot">Did you forget your password?</a>
      </div>
    </div>
  </div>
</p-dialog>
