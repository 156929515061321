<p-dialog
    [(visible)]="displayGalleria"
    [modal]="true"
    appendTo="body"
    styleClass="disable-scroll"
    [closeOnEscape]="false"
    [closable]="false"
    [style]="{'min-width': '100vw', 'min-height': '100vh', 'max-width': '100vw', 'max-height': '100vh'}"
    (onHide)="closeGalleria()"
>
    <p-galleria
        [value]="images"
        [(visible)]="displayGalleria"
        [responsiveOptions]="responsiveOptions"
        [containerStyle]="{'max-width': '50%'}"
        [numVisible]="9"
        [circular]="true"
        [fullScreen]="true"
        [showItemNavigators]="true"
    >
        <ng-template pTemplate="item" let-item>
            <img [src]="item.itemImageSrc" style="max-width: 80vw; display: block; max-height: 80vh;"/>
        </ng-template>
        <ng-template pTemplate="thumbnail" let-item>
            <div class="grid grid-nogutter justify-content-center">
                <img [src]="item.itemImageSrc" style="display: block; max-width: 10vw; max-height: 10vh;"/>
            </div>
        </ng-template>
    </p-galleria>
</p-dialog>

