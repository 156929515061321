import { Component, ViewChild, OnInit, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'app/login/login.service';
import { AccountService } from 'app/core/auth/account.service';
import { HomeService } from 'app/home/home.service';

@Component({
  selector: 'auth-login',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('username', { static: false })
  username?: ElementRef;
  isVisible = true;

  authenticationError = false;

  loginForm = this.fb.group({
    username: [null, [Validators.required]],
    password: [null, [Validators.required]],
    rememberMe: [false],
  });

  constructor(
    private accountService: AccountService,
    private loginService: LoginService,
    private router: Router,
    private fb: UntypedFormBuilder,
    public homeService: HomeService
  ) {}

  ngOnInit(): void {
    // if already authenticated then navigate to home page
    this.accountService.identity().subscribe(() => {
      if (this.accountService.isAuthenticated()) {
        this.router.navigate(['']);
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.username) {
      this.username.nativeElement.focus();
    }
  }

  login(): void {
    this.loginService
      .login({
        username: this.loginForm.get('username')!.value,
        password: this.loginForm.get('password')!.value,
        rememberMe: this.loginForm.get('rememberMe')!.value,
      })
      .subscribe(
        () => {
          this.authenticationError = false;

          this.closeDialog();

          if (!this.router.getCurrentNavigation()) {
            // There were no routing during login (eg from navigationToStoredUrl)
            this.router.navigate(['']);
          }
        },
        () => (this.authenticationError = true)
      );
  }

  ngOnDestroy(): void {
    if (this.accountService.isAuthenticated()) {
      // this.homeService.setApps(this.accountService.getUserLogin());
      // this.homeService.loadDateFormats(this.accountService.getUserLogin());
      this.homeService.getLoggedInName.emit(this.accountService.getUserLogin());
    }
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  requestResetPassword(): void {
    this.closeDialog();
    this.router.navigate(['/account/reset', 'request']);
  }

  closeDialog(): void{
    this.isVisible = false;
  }
}
