import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { DATE_FORMAT } from 'app/config/input.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IIntrastatReport, getIntrastatReportIdentifier } from '../intrastat-report.model';
import { IntrastatReportType } from 'app/entities/enumerations/intrastat-report-type.model';
import { IIntrastatReportLine } from '../intrastat-report-lines/intrastat-report-line.model';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';

export type EntityResponseType = HttpResponse<IIntrastatReport>;
export type EntityArrayResponseType = HttpResponse<IIntrastatReport[]>;

@Injectable({ providedIn: 'root' })
export class IntrastatReportService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/intrastat-reports');

  constructor(
    protected http: HttpClient,
    protected applicationConfigService: ApplicationConfigService,
    protected globalsService: GlobalsService,
  ) {}

  create(intrastatReport: IIntrastatReport): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(intrastatReport);
    return this.http
      .post<IIntrastatReport>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(intrastatReport: IIntrastatReport): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(intrastatReport);
    return this.http
      .put<IIntrastatReport>(`${this.resourceUrl}/${this.globalsService.getParam("AppId")}&${getIntrastatReportIdentifier(intrastatReport) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(intrastatReport: IIntrastatReport): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(intrastatReport);
    return this.http
      .patch<IIntrastatReport>(`${this.resourceUrl}/${getIntrastatReportIdentifier(intrastatReport) as number}`, copy, {
        observe: 'response',
      })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IIntrastatReport>(`${this.resourceUrl}/withDetails/${this.globalsService.getParam("AppId")}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IIntrastatReport[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  addIntrastatReportToCollectionIfMissing(
    intrastatReportCollection: IIntrastatReport[],
    ...intrastatReportsToCheck: (IIntrastatReport | null | undefined)[]
  ): IIntrastatReport[] {
    const intrastatReports: IIntrastatReport[] = intrastatReportsToCheck.filter(isPresent);
    if (intrastatReports.length > 0) {
      const intrastatReportCollectionIdentifiers = intrastatReportCollection.map(
        intrastatReportItem => getIntrastatReportIdentifier(intrastatReportItem)!
      );
      const intrastatReportsToAdd = intrastatReports.filter(intrastatReportItem => {
        const intrastatReportIdentifier = getIntrastatReportIdentifier(intrastatReportItem);
        if (intrastatReportIdentifier == null || intrastatReportCollectionIdentifiers.includes(intrastatReportIdentifier)) {
          return false;
        }
        intrastatReportCollectionIdentifiers.push(intrastatReportIdentifier);
        return true;
      });
      return [...intrastatReportsToAdd, ...intrastatReportCollection];
    }
    return intrastatReportCollection;
  }

  loadLines(reportType: IntrastatReportType, yearMonth: number): Observable<HttpResponse<IIntrastatReportLine[]>> {
    return this.http
      .get<IIntrastatReportLine[]>(`${this.resourceUrl}/lines-load/${this.globalsService.getParam("AppId")}&${reportType}&${yearMonth}`, { observe: 'response' })
      .pipe(map((res: HttpResponse<IIntrastatReportLine[]>) => this.convertLineDateArrayFromServer(res)));
  }

  getFile(id: number): Observable<HttpResponse<Blob>> {
    return this.http.get<Blob>(`${this.resourceUrl}/report-file?appId.equals=${this.globalsService.getParam("AppId")}&id.equals=${id}`, { observe: 'response', responseType: 'blob' as 'json' });
  }

  closing(yearMonthType: string, oldYearMonth: number): Observable<IBackEndMessage[]> {
    return this.http.put<IBackEndMessage[]>(`api/year-months/close/${this.globalsService.getParam("AppId")}&${yearMonthType}&${oldYearMonth}`,null);
  }

  protected convertDateFromClient(intrastatReport: IIntrastatReport): IIntrastatReport {
    return Object.assign({}, intrastatReport, {
      intrastatReportDate: intrastatReport.intrastatReportDate?.isValid()
        ? intrastatReport.intrastatReportDate.format(DATE_FORMAT)
        : undefined,
      spareDate1: intrastatReport.spareDate1?.isValid() ? intrastatReport.spareDate1.format(DATE_FORMAT) : undefined,
      spareDate2: intrastatReport.spareDate2?.isValid() ? intrastatReport.spareDate2.format(DATE_FORMAT) : undefined,
      createdDate: intrastatReport.createdDate?.isValid() ? intrastatReport.createdDate.toJSON() : undefined,
      lastModifiedDate: intrastatReport.lastModifiedDate?.isValid() ? intrastatReport.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.intrastatReportDate = res.body.intrastatReportDate ? dayjs(res.body.intrastatReportDate) : undefined;
      res.body.spareDate1 = res.body.spareDate1 ? dayjs(res.body.spareDate1) : undefined;
      res.body.spareDate2 = res.body.spareDate2 ? dayjs(res.body.spareDate2) : undefined;
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((intrastatReport: IIntrastatReport) => {
        intrastatReport.intrastatReportDate = intrastatReport.intrastatReportDate ? dayjs(intrastatReport.intrastatReportDate) : undefined;
        intrastatReport.spareDate1 = intrastatReport.spareDate1 ? dayjs(intrastatReport.spareDate1) : undefined;
        intrastatReport.spareDate2 = intrastatReport.spareDate2 ? dayjs(intrastatReport.spareDate2) : undefined;
        intrastatReport.createdDate = intrastatReport.createdDate ? dayjs(intrastatReport.createdDate) : undefined;
        intrastatReport.lastModifiedDate = intrastatReport.lastModifiedDate ? dayjs(intrastatReport.lastModifiedDate) : undefined;
      });
    }
    return res;
  }

  protected convertLineDateArrayFromServer(res: HttpResponse<IIntrastatReportLine[]>): HttpResponse<IIntrastatReportLine[]> {
    if (res.body) {
      res.body.forEach((intrastatReportLine: IIntrastatReportLine) => {
        intrastatReportLine.spareDate1 = intrastatReportLine.spareDate1 ? dayjs(intrastatReportLine.spareDate1) : undefined;
        intrastatReportLine.spareDate2 = intrastatReportLine.spareDate2 ? dayjs(intrastatReportLine.spareDate2) : undefined;
        intrastatReportLine.createdDate = intrastatReportLine.createdDate ? dayjs(intrastatReportLine.createdDate) : undefined;
        intrastatReportLine.lastModifiedDate = intrastatReportLine.lastModifiedDate ? dayjs(intrastatReportLine.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
