import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { DATE_FORMAT } from 'app/config/input.constants';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IStore, getStoreIdentifier } from '../store.model';
import { GlobalsService } from 'app/shared/globals/globals.service';

export type EntityResponseType = HttpResponse<IStore>;
export type EntityArrayResponseType = HttpResponse<IStore[]>;

@Injectable({ providedIn: 'root' })
export class StoreService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/stores');

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService, protected globalsService: GlobalsService) {}
  create(store: IStore): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(store);
    return this.http
      .post<IStore>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(store: IStore): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(store);
    return this.http
      .put<IStore>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getStoreIdentifier(store) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(store: IStore): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(store);
    return this.http
      .patch<IStore>(`${this.resourceUrl}/${getStoreIdentifier(store) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IStore>(`${this.resourceUrl}/withDetails/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IStore[]>(`${this.resourceUrl}/?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryForUser(referenceDate?: string): Observable<EntityArrayResponseType> {
    return this.http
      .get<IStore[]>(`${this.resourceUrl}/for-user/${this.globalsService.getParam("AppId")}&${referenceDate}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  addStoreToCollectionIfMissing(storeCollection: IStore[], ...storesToCheck: (IStore | null | undefined)[]): IStore[] {
    const stores: IStore[] = storesToCheck.filter(isPresent);
    if (stores.length > 0) {
      const storeCollectionIdentifiers = storeCollection.map(storeItem => getStoreIdentifier(storeItem)!);
      const storesToAdd = stores.filter(storeItem => {
        const storeIdentifier = getStoreIdentifier(storeItem);
        if (storeIdentifier == null || storeCollectionIdentifiers.includes(storeIdentifier)) {
          return false;
        }
        storeCollectionIdentifiers.push(storeIdentifier);
        return true;
      });
      return [...storesToAdd, ...storeCollection];
    }
    return storeCollection;
  }

  protected convertDateFromClient(store: IStore): IStore {
    return Object.assign({}, store, {
      validFrom: store.validFrom?.isValid() ? store.validFrom.format(DATE_FORMAT) : undefined,
      validTo: store.validTo?.isValid() ? store.validTo.format(DATE_FORMAT) : undefined,
      createdDate: store.createdDate?.isValid() ? store.createdDate.toJSON() : undefined,
      lastModifiedDate: store.lastModifiedDate?.isValid() ? store.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.validFrom = res.body.validFrom ? dayjs(res.body.validFrom) : undefined;
      res.body.validTo = res.body.validTo ? dayjs(res.body.validTo) : undefined;
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((store: IStore) => {
        store.validFrom = store.validFrom ? dayjs(store.validFrom) : undefined;
        store.validTo = store.validTo ? dayjs(store.validTo) : undefined;
        store.createdDate = store.createdDate ? dayjs(store.createdDate) : undefined;
        store.lastModifiedDate = store.lastModifiedDate ? dayjs(store.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
