import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { IColumnFilter } from 'app/shared/interfaces/column-filter/column-filter.model';
import { FilterMetadata } from 'primeng/api/filtermetadata';
import * as dayjs from 'dayjs';
import { DATE_FORMAT } from 'app/config/input.constants';
import { IEntityWithFieldTranslations } from '../base-classes/base-entity.service';
import { Alert } from 'app/core/util/alert.service';
import { IAccessRightsByUser } from 'app/entities/access-right/access-right.model';

@Injectable()
export class GlobalsService {

  global = JSON.stringify({
    UserName: '',
    AppId: '',
    OwnPartnerId: -99,
    LocalCurrency: 'HUF',
    NumberFormat: 'hu',
    DateFormat: 'YYYY.MM.DD.',
    DatetimeFormat: 'YYYY.MM.DD. HH:mm',
    TimeFormat: 'HH:mm',
    TimestampFormat: 'YYYY.MM.DD. HH:mm:ss',
    YearMonthFormat: 'YYYY.MM',
    Messages: [],
    AccessRigths: [],
    FullRights: false,
    Language: 'hu',
    CommerceFlag: false,
    IntrastatExportFlag: false,
    IntrastatImportFlag: false,
    EkaerFlag: false,
    EdiFlag: false,
    Stores: []
  });
  salt = '660325581619';
  tablefilter: IColumnFilter[] = [];
  columnFilter: IColumnFilter = {};
  filterMetadataArray: FilterMetadata[] = [];

  public getParam( key: string ): string {
    const encGlobals = sessionStorage.getItem('globals')!;
    let val = '';

    if (encGlobals) {
      const decGlobals = this.decryptAES(encGlobals);
      const glVar = JSON.parse(decGlobals);
      val = glVar[key] ?? JSON.parse(this.global)[key];
    } else {
      val = JSON.parse(this.global)[key];
    }
    return val;
  }

  public setParam( key: string, val: string | number | boolean | Alert[] | IAccessRightsByUser[] | string[]): void {
    let encGlobals = sessionStorage.getItem('globals')!;

    if (encGlobals) {
      const decGlobals = this.decryptAES(encGlobals);

      const glVar = JSON.parse(decGlobals);
      glVar[key] = val;

      encGlobals = this.encryptAES(JSON.stringify(glVar));

      sessionStorage.setItem('globals', encGlobals);
    }
  }

  public encryptAES(text: string): string {
    const _key = CryptoJS.enc.Utf8.parse(this.salt);
    const _iv = CryptoJS.enc.Utf8.parse(this.salt);

    const encrypted = CryptoJS.AES.encrypt(text, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  public decryptAES(text: string): string {
    const _key = CryptoJS.enc.Utf8.parse(this.salt);
    const _iv = CryptoJS.enc.Utf8.parse(this.salt);

    const decrypted = CryptoJS.AES.decrypt(text, _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);
    return decrypted;
  }

  public async getFileHash(file: File): Promise<string> {
    let hash = '';
    await file.text().then((str)=> {
      hash = CryptoJS.SHA1(str).toString()
    });  
    return hash;
  }

  public getTableFilter(filter: { [s: string]: FilterMetadata | FilterMetadata[] | undefined}): IColumnFilter[] {
    const keys = Object.keys(filter);
    let valueArray = [];
    this.tablefilter = [];
    this.columnFilter = {};
    let jhiMatchMode = '';
    let isDate = false;
    let isDatetime = false;

    for (let i = 0; i < keys.length; i++) {
      this.filterMetadataArray = [];

      if (Array.isArray(Object.values(filter)[i])) {
        this.filterMetadataArray = this.filterMetadataArray.concat(Object.values(filter)[i]!);
      } else {
        this.filterMetadataArray.push(Object.values(filter)[i] as FilterMetadata);
      }

      for (let j = 0; j < this.filterMetadataArray.length; j++) {
        let str = '';
        const l_operator = this.filterMetadataArray[j].operator ?? 'and';
        if (this.filterMetadataArray[j].value != null) {
          valueArray = [];

          switch(this.filterMetadataArray[j].matchMode) { 
            case 'startsWith': { 
              jhiMatchMode = 'like'; 
              str = this.filterMetadataArray[j].value as string + '%'; 
              break; 
            } 
            case 'endsWith': { 
              jhiMatchMode = 'like'; 
              str = this.filterMetadataArray[j].value as string; 
              str = '%' + str; 
              break; 
            } 
            case 'lt': { 
              jhiMatchMode = 'lessThan'; 
              break; 
            } 
            case 'lte': { 
              jhiMatchMode = 'lessThanOrEqual'; 
              break; 
            } 
            case 'gt': { 
              jhiMatchMode = 'greaterThan'; 
              break; 
            } 
            case 'gte': { 
              jhiMatchMode = 'greaterThanOrEqual'; 
              break; 
            } 
            case 'dateIs': { 
              jhiMatchMode = 'equals';
              isDate = true; 
              break; 
            } 
            case 'dateIsNot': { 
              jhiMatchMode = 'notEquals';
              isDate = true; 
              break; 
            } 
            case 'dateBefore': { 
              jhiMatchMode = 'lessThan';
              isDate = true; 
              break; 
            } 
            case 'dateAfter': { 
              jhiMatchMode = 'greaterThan'; 
              isDate = true; 
              break; 
            } 
            case 'contains': { 
              if(typeof this.filterMetadataArray[j].value === 'boolean') {
                jhiMatchMode = 'equals'; 
              } else {
                jhiMatchMode = 'contains'; 
              }; 
              break; 
            } 
            case 'notContains': { 
              if(typeof this.filterMetadataArray[j].value === 'boolean') {
                jhiMatchMode = 'notEquals'; 
              } else {
              jhiMatchMode = 'doesNotContain'; 
              }; 
              break; 
            } 
            default: { 
              jhiMatchMode = this.filterMetadataArray[j].matchMode!; 
              break; 
            } 
          }

          switch (keys[i]) {
            case "lastModifiedDate" : {
              isDatetime = true;
              break;
            }
            case "createdDate" : {
              isDatetime = true;
              break;
            }
          }

          if (jhiMatchMode === 'like') {
            valueArray.push(str);
          } else {
            if (isDatetime) {
              valueArray.push(dayjs(this.filterMetadataArray[j].value).toJSON());
            } else if (isDate) {
              valueArray.push( dayjs(this.filterMetadataArray[j].value).format(DATE_FORMAT))
            } else {
              valueArray.push( this.filterMetadataArray[j].value);
            }
          }

          switch(jhiMatchMode) { 
            case 'between': { 
              let value = [];
              value.push(valueArray[0][0]);
              this.columnFilter = { column: keys[i], matchMode: 'greaterThanOrEqual', values: value, operator: l_operator };
              this.tablefilter.push(this.columnFilter);

              value = [];
              value.push(valueArray[0][1]);
              this.columnFilter = { column: keys[i], matchMode: 'lessThanOrEqual', values: value, operator: l_operator  };
              this.tablefilter.push(this.columnFilter);
              break; 
            } 
            default: { 
              this.columnFilter = { column: keys[i], matchMode: jhiMatchMode, values: valueArray, operator: l_operator };

              if (l_operator === 'or') {
                this.tablefilter.push(this.columnFilter);
                this.columnFilter = { column: keys[i], matchMode: 'orFlag', values: ["true"] };
              }

              this.tablefilter.push(this.columnFilter);
              break; 
            } 
          } 
        }
      }  
    }

    return this.tablefilter;
  }


  public formatYearMonth(yearMonth: number | string | null | undefined): string {
    const strYear = yearMonth?.toString().slice(0,4);
    const strMonth = yearMonth?.toString().slice(4,6); 

    return yearMonth ? this.getParam("YearMonthFormat").replace('YYYY',strYear!).replace('MM',strMonth!) : '';
  }  

  public yearMonthToNumber(yearMonthStr: string): number {
    const yearMonthFormat = this.getParam("YearMonthFormat");
    const yearPos = yearMonthFormat.indexOf('YYYY');
    const monthPos = yearMonthFormat.indexOf('MM');

    const yearMonthNumber = +(yearMonthStr.slice(yearPos, yearPos + 4) + yearMonthStr.slice(monthPos, monthPos + 2));
    return yearMonthNumber;
  }

  convertYYYYMMToDate(date: number): Date {
    const YYYY = date.toString().slice(0, 4);
    const MM = date.toString().slice(4, 6);
    return new Date(+YYYY, +MM - 1);
  }

  convertDateToYYYYMM(date: Date): number {
    const YYYY = date.getFullYear();
    const MM = date.getMonth() + 1;
    return YYYY * 100 + MM;
  }

  public prepareFieldTranslationsJSON(copy: IEntityWithFieldTranslations): IEntityWithFieldTranslations {

    // a lehetséges idézőjelek miatt object-té kell alakítani a Map<string,string>-et, mert így nem száll el a stringify
    if (copy.commentTranslations && copy.commentTranslations.size > 0) {
      const obj = Object.fromEntries(copy.commentTranslations);
      var jsonString = JSON.stringify(obj);
      copy.commentTranslations = JSON.parse(jsonString);
    }

    if (copy.nameTranslations && copy.nameTranslations.size > 0) {
      const obj = Object.fromEntries(copy.nameTranslations);
      var jsonString = JSON.stringify(obj);
      copy.nameTranslations = JSON.parse(jsonString);
    }

    if (copy.specificationTranslations && copy.specificationTranslations.size > 0) {
      const obj = Object.fromEntries(copy.specificationTranslations);
      var jsonString = JSON.stringify(obj);
      copy.specificationTranslations = JSON.parse(jsonString);
    }

    let copyStr = JSON.stringify(copy);

    if (copy.nameTranslations && copy.nameTranslations.size > 0) {
      let nameTranslationsStr = '';
      for (const [key, value] of copy.nameTranslations) {
        nameTranslationsStr += `"${key}" : "${value}",`;
      }
      nameTranslationsStr = nameTranslationsStr.slice(0, -1);
      copyStr = copyStr.replace('"nameTranslations":{}', '"nameTranslations":{' + nameTranslationsStr + '}');
    }

    if (copy.commentTranslations && copy.commentTranslations.size > 0) {
      let commentTranslationsStr = '';
      for (const [key, value] of copy.commentTranslations) {
        commentTranslationsStr += `"${key}" : "${value}",`;
      }
      commentTranslationsStr = commentTranslationsStr.slice(0, -1);
      copyStr = copyStr.replace('"commentTranslations":{}', '"commentTranslations":{' + commentTranslationsStr + '}');
    }

    if (copy.specificationTranslations && copy.specificationTranslations.size > 0) {
      let specificationTranslationsStr = '';
      for (const [key, value] of copy.specificationTranslations) {
        specificationTranslationsStr += `"${key}" : "${value}",`;
      }
      specificationTranslationsStr = specificationTranslationsStr.slice(0, -1);
      copyStr = copyStr.replace('"specificationTranslations":{}', '"specificationTranslations":{' + specificationTranslationsStr + '}');
    }
    
    console.log(copyStr);

    const entityWithNameTranslationsJSON = JSON.parse(copyStr) as IEntityWithFieldTranslations;
    return entityWithNameTranslationsJSON;
  }  

  getAccessRightsByFunction(p_FunctionCode: string): string[] {
    let ar: string[] = [];
    const accesRights = this.getParam('AccessRigths') as unknown as IAccessRightsByUser[];

    accesRights.every(value => {
      if (value.functionCode === p_FunctionCode) {
        ar = JSON.parse(value.operations!);
        return false;
      }
      return true;
    })
    return ar;
  }

  hasAccessRightByOperation(p_Operations: string[], p_Operation: string): boolean {
    let ar = false;
    const arr = Array.from(p_Operations);

    arr.every(op => {
      if (op.toString() === p_Operation) {
        ar = true;
        return false;
      }
      return true;
    });
    return ar;
  }

  hasAccessRightByFunctionAndOperation(p_FunctionCode: string, p_Operation: string): boolean {
    let result = false;
    let ar: string[] = [];

    const accesRights = this.getParam('AccessRigths') as unknown as IAccessRightsByUser[];

    accesRights.every(value => {
      if (value.functionCode === p_FunctionCode) {
        ar = JSON.parse(value.operations!);
        return false;
      }
      return true;
    })

    if (ar.length > 0) {
      ar.every(op => {
        if (op.toString() === p_Operation) {
          result = true;
          return false;
        }
        return true;
      });
      }
    return result;
  }
}
