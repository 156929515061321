import { Component } from '@angular/core';
import { ApplicationService } from 'app/entities/application/service/application.service';
import { AccountService } from 'app/core/auth/account.service';
import { AlertService } from 'app/core/util/alert.service';
import { DataSharingService } from 'app/shared/services/data-sharing.service';
import { GlobalsService } from 'app/shared/globals/globals.service';

@Component({
  selector: 'auth-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  applicationName = '';
  messageIconStyle = 'padding: 0px 10px; cursor: pointer;';

  constructor(
    protected applicationService: ApplicationService,
    private accountService: AccountService,
    protected globalsService: GlobalsService,
    protected alertService: AlertService,
    private dataSharingService: DataSharingService,
  ) {
    this.dataSharingService.appName.subscribe( value => {
      this.applicationName = value;
    });
    
    if (this.isAuthenticated() && this.applicationName === '') {
      this.getApplication();
    }

    this.dataSharingService.nofAlerts.subscribe( value => {
      if (value === 0) {
        this.messageIconStyle = 'padding: 0px 10px; cursor: pointer;'
      } else {
        this.messageIconStyle = 'padding: 0px 10px; cursor: pointer; color: #2196f3'
      };
    });
    
  }

  getApplication(): void {
    const appId = this.globalsService.getParam("AppId");
      this.applicationService.find(appId).subscribe(res => {
        setTimeout(() => {
          const application = res.body ?? undefined;
          if (application) {
            this.applicationName = application.appFullName!;
          }
      }, 200);
    });
  }

  toggleSidebar(): void{
    if (this.globalsService.getParam("Messages").length > 0) {
      this.dataSharingService.visibleSidebar.next(true);
    }
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

}
