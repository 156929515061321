import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { Pagination } from 'app/core/request/request.model';
import { IAdminUserDTO } from './adminuser.model';

export type EntityResponseType = HttpResponse<IAdminUserDTO>;
export type EntityArrayResponseType = HttpResponse<IAdminUserDTO[]>;

@Injectable({ providedIn: 'root' })
export class AdminUserService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/admin/users');

  constructor(private http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  query(req?: Pagination): Observable<HttpResponse<IAdminUserDTO[]>> {
    const options = createRequestOption(req);
    return this.http
      .get<IAdminUserDTO[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  find(login: string): Observable<EntityResponseType> {
    return this.http
      .get<IAdminUserDTO>(`${this.resourceUrl}/${login}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  protected convertDateFromClient(adminUser: IAdminUserDTO): IAdminUserDTO {
    return Object.assign({}, adminUser, {
      createdDate: adminUser.createdDate?.isValid() ? adminUser.createdDate.toJSON() : undefined,
      lastModifiedDate: adminUser.lastModifiedDate?.isValid() ? adminUser.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((adminUser: IAdminUserDTO) => {
        adminUser.createdDate = adminUser.createdDate ? dayjs(adminUser.createdDate) : undefined;
        adminUser.lastModifiedDate = adminUser.lastModifiedDate ? dayjs(adminUser.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
