import { Component, OnInit, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';

import { AccountService } from 'app/core/auth/account.service';
import { Account } from 'app/core/auth/account.model';

import { ApplicationService } from 'app/entities/application/service/application.service';
import { IApplication } from 'app/entities/application/application.model';
import { AccessRightService } from 'app/entities/access-right/service/access-right.service';

import { HomeService } from './home.service';

import { GlobalsService } from 'app/shared/globals/globals.service';
import { DataSharingService } from 'app/shared/services/data-sharing.service';
import { AlertService } from 'app/core/util/alert.service';
import { LoginComponent } from 'app/login/login.component';
import { StoreService } from 'app/entities/store/service/store.service';
import * as dayjs from 'dayjs';
import { DATE_FORMAT } from 'app/config/input.constants';

@Component({
  selector: 'auth-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('dialogContainer', { read: ViewContainerRef }) dialogContainer!: ViewContainerRef;
  account: Account | null = null;
  authSubscription?: Subscription;
  responsiveOptions: any[];
  application?: IApplication;

  constructor(
    private accountService: AccountService,
    // private router: Router,
    public homeService: HomeService,
    protected applicationService: ApplicationService,
    private accessRightService: AccessRightService,
    protected globalsService: GlobalsService,
    private dataSharingService: DataSharingService,
    private alertService: AlertService,
    protected router: Router,
    protected storeService: StoreService,
  ) {
    this.responsiveOptions = [
      {
        breakpoint: '1024px',
        numVisible: 3,
        numScroll: 3,
      },
      {
        breakpoint: '768px',
        numVisible: 2,
        numScroll: 2,
      },
      {
        breakpoint: '560px',
        numVisible: 1,
        numScroll: 1,
      },
    ];
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this.authSubscription = this.accountService.getAuthenticationState().subscribe(account => {
      this.account = account;
      if (this.account) {
        this.homeService.setApps(this.account.login);
        setTimeout(() => {
          if (this.getApps() === 1) {
            this.setApplicationID(this.homeService.appID)
          }
        },500);
        this.homeService.loadDateFormats(this.account.login);
        this.getApplication();
        this.globalsService.setParam("UserName",this.account.login);
      }
    });
    const appID = this.globalsService.getParam("AppId") ;
    if (appID === '') {
      sessionStorage.setItem('globals', this.globalsService.encryptAES(this.globalsService.global));
    }
    this.dataSharingService.activeMenuItem.next('S0');
  }

  isAuthenticated(): boolean {
    return this.accountService.isAuthenticated();
  }

  login(): void {
    this.dataSharingService.displayMenu.next(false);
    this.openLoginDialog();
  }

  openLoginDialog(): void{
    // Lazy load component
    this.dialogContainer.createComponent<LoginComponent>(LoginComponent);
  }

  ngOnDestroy(): void {
    if (this.authSubscription) {
      this.authSubscription.unsubscribe();
    }
  }

  setApplicationID(appId: string): void {
    this.globalsService.setParam( "AppId", appId);
    this.dataSharingService.nofAlerts.next(0);
    this.alertService.clear();
    this.homeService.getLoggedInName.emit(this.account?.login);

    this.applicationService.find(appId).subscribe((res: HttpResponse<IApplication>) => {
      this.application = res.body ?? undefined;
      this.dataSharingService.appName.next(this.application ? this.application.appFullName! : '');

      if (this.application?.jsonContent) {
        const jsonContent = this.application.jsonContent as {};
        const keys = Object.keys(jsonContent);

        for (let i = 0; i < keys.length; i++) {
            this.globalsService.setParam( keys[i], Object.values(jsonContent)[i] as string);
        }
      } else {
        this.globalsService.setParam( 'OwnPartnerId',-99);
      }

      if (this.account?.login) {
        this.accessRightService.getAccessRightsByUser(this.account.login).subscribe(res => {
          this.globalsService.setParam( "AccessRigths", res.body ?? []);
          this.accessRightService.hasFullRights(this.account!.login).subscribe(r => {
            this.globalsService.setParam( "FullRights", r.body ?? false);
            // if (!this.dataSharingService.displayMenu.getValue()) {
            //   this.dataSharingService.displayMenu.next(true);
            // }
            this.refreshMenu();
            if (r.body === true || this.hasAccess("MENU_DASHBOARD")) {
              this.router.navigate(['dashboard']);
              this.dataSharingService.activeMenuItem.next('S1');
            }
          })
        })

        if (this.globalsService.getParam("CommerceFlag").toString() === "true") {
          this.storeService.queryForUser(dayjs().format(DATE_FORMAT)).subscribe(resStores =>{
            if (resStores.body) {
              const storeCodes: string[] = [];
              resStores.body.forEach(store => storeCodes.push(store.code!));
              this.globalsService.setParam("Stores",storeCodes);
            }
          })
        }  
      }
    })
  }

  getApplicationID(): string {
    return this.globalsService.getParam("AppId") ;
  }

  getApplication(): void {
    const appId = this.globalsService.getParam("AppId");
    if (appId !== '') {
      this.applicationService.find(appId).subscribe((res: HttpResponse<IApplication>) => {
        setTimeout(() => {
          this.application = res.body ?? undefined;
        }, 200);
      });
    }
  }

  getApps(): number {
    return this.homeService.appsNum || 0;
  }

  refreshMenu(): void {
    this.dataSharingService.displayMenu.next(false);
    setTimeout(() => this.dataSharingService.displayMenu.next(true),0);
}

  hasAccess( functionCode: string): boolean {
    return this.accountService.hasAnyAuthority('ROLE_ADMIN') || this.globalsService.getAccessRightsByFunction(functionCode).length > 0;
  }

}

