import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IGlStatement, getGlStatementIdentifier } from '../gl-statement.model';
import { GlobalsService } from 'app/shared/globals/globals.service';
import { DocType } from 'app/entities/enumerations/doc-type.model'
import { IBackEndMessage } from 'app/shared/interfaces/back-end-message/back-end-message.model';

export type EntityResponseType = HttpResponse<IGlStatement>;
export type EntityArrayResponseType = HttpResponse<IGlStatement[]>;

@Injectable({ providedIn: 'root' })
export class GlStatementService {
  protected resourceUrl = this.applicationConfigService.getEndpointFor('api/gl-statements');

  constructor(protected http: HttpClient, protected applicationConfigService: ApplicationConfigService, protected globalsService: GlobalsService) {}

  create(glStatement: IGlStatement): Observable<EntityResponseType> {
    let copy = this.convertDateFromClient(glStatement);
    copy = this.globalsService.prepareFieldTranslationsJSON(copy);

    return this.http
      .post<IGlStatement>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(glStatement: IGlStatement): Observable<EntityResponseType> {
    let copy = this.convertDateFromClient(glStatement);
    copy = this.globalsService.prepareFieldTranslationsJSON(copy);

    return this.http
      .put<IGlStatement>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${getGlStatementIdentifier(glStatement) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  partialUpdate(glStatement: IGlStatement): Observable<EntityResponseType> {
    let copy = this.convertDateFromClient(glStatement);
    copy = this.globalsService.prepareFieldTranslationsJSON(copy);

    return this.http
      .patch<IGlStatement>(`${this.resourceUrl}/${getGlStatementIdentifier(glStatement) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IGlStatement>(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}`, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  findGlAccountName(glYear: number, glAccountNumber: string): Observable<HttpResponse<string>> {
    return this.http.get<string>(`${this.resourceUrl}/find/${this.globalsService.getParam("AppId")}&${glYear}&${glAccountNumber}`, { observe: 'response' });
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${this.globalsService.getParam('AppId')}&${id}`, { observe: 'response' });
  }

  queryGlYears(): Observable<HttpResponse<number[]>> {
    return this.http.get<number[]>(`${this.resourceUrl}/gl-years/${this.globalsService.getParam("AppId")}`, { observe: 'response' });
  }

  queryOutVatRateGlAccounts(): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}&glAccountNumber.like=467%25`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryInVatRateGlAccounts(): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}&glAccountNumber.like=466%25`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryOutVatTypeGlAccounts(year: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}&glYear.equals=${year.toString()}&glAccountNumber.like=467%25`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryInVatTypeGlAccounts(year: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}?appId.equals=${this.globalsService.getParam("AppId")}&glYear.equals=${year.toString()}&glAccountNumber.like=466%25`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryByCategory(glCategoryId: number, glYear: number): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}/by-category/${this.globalsService.getParam("AppId")}&${glCategoryId}&${glYear}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  queryByDocType(glYear: number, docType: DocType): Observable<EntityArrayResponseType> {
    return this.http
      .get<IGlStatement[]>(`${this.resourceUrl}/by-doctype/${this.globalsService.getParam("AppId")}&${glYear}&${docType}`, { observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  generateFromPreviousYear(newGlYear: number): Observable<IBackEndMessage[]> {
    return this.http.post<IBackEndMessage[]>(`${this.resourceUrl}/generate/${this.globalsService.getParam('AppId')}&${newGlYear}`, { observe: 'response' });
  }

  loadOpeningValues(newGlYear: number): Observable<IBackEndMessage[]> {
    return this.http.post<IBackEndMessage[]>(`${this.resourceUrl}/load_opening/${this.globalsService.getParam('AppId')}&${newGlYear}`, { observe: 'response' });
  }

  closeGlYear(yearToClose: number): Observable<IBackEndMessage[]> {
    return this.http.post<IBackEndMessage[]>(`${this.resourceUrl}/close_year/${this.globalsService.getParam('AppId')}&${yearToClose}`, { observe: 'response' });
  }

  addGlStatementToCollectionIfMissing(
    glStatementCollection: IGlStatement[],
    ...glStatementsToCheck: (IGlStatement | null | undefined)[]
  ): IGlStatement[] {
    const glStatements: IGlStatement[] = glStatementsToCheck.filter(isPresent);
    if (glStatements.length > 0) {
      const glStatementCollectionIdentifiers = glStatementCollection.map(glStatementItem => getGlStatementIdentifier(glStatementItem)!);
      const glStatementsToAdd = glStatements.filter(glStatementItem => {
        const glStatementIdentifier = getGlStatementIdentifier(glStatementItem);
        if (glStatementIdentifier == null || glStatementCollectionIdentifiers.includes(glStatementIdentifier)) {
          return false;
        }
        glStatementCollectionIdentifiers.push(glStatementIdentifier);
        return true;
      });
      return [...glStatementsToAdd, ...glStatementCollection];
    }
    return glStatementCollection;
  }

  protected convertDateFromClient(glStatement: IGlStatement): IGlStatement {
    return Object.assign({}, glStatement, {
      createdDate: glStatement.createdDate?.isValid() ? glStatement.createdDate.toJSON() : undefined,
      lastModifiedDate: glStatement.lastModifiedDate?.isValid() ? glStatement.lastModifiedDate.toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((glStatement: IGlStatement) => {
        glStatement.createdDate = glStatement.createdDate ? dayjs(glStatement.createdDate) : undefined;
        glStatement.lastModifiedDate = glStatement.lastModifiedDate ? dayjs(glStatement.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}