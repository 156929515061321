import * as dayjs from 'dayjs';
import { StoreLocationType } from '../enumerations/store-location-type';
import { IStoreUser } from 'app/entities/store/store-user.model'

export interface IStore {
  id?: number;
  appId?: string | null;
  code?: string;
  name?: string;
  storeLocationFlag?: boolean | null;
  storeLocFormatMask?: string | null;
  storeLocations?: IStoreLocation[] | null;
  validFrom?: dayjs.Dayjs;
  validTo?: dayjs.Dayjs | null;
  createdBy?: string | null;
  createdDate?: dayjs.Dayjs | null;
  lastModifiedBy?: string | null;
  lastModifiedDate?: dayjs.Dayjs | null;
  prevLastModifiedDate?: string | null;
  storeUsers?: IStoreUser[] | null;
}

export class Store implements IStore {
  constructor(
    public id?: number,
    public appId?: string | null,
    public code?: string,
    public name?: string,
    public storeLocationFlag?: boolean | null,
    public storeLocFormatMask?: string | null,
    public storeLocations?: IStoreLocation[] | null,
    public validFrom?: dayjs.Dayjs,
    public validTo?: dayjs.Dayjs | null,
    public createdBy?: string | null,
    public createdDate?: dayjs.Dayjs | null,
    public lastModifiedBy?: string | null,
    public lastModifiedDate?: dayjs.Dayjs | null,
    public prevLastModifiedDate?: string | null,
    public storeUsers?: IStoreUser[] | null
    ) {
    this.storeLocationFlag = this.storeLocationFlag ?? false;
  }
}
export interface IStoreLocation {
  location?: string;
  locationType?: StoreLocationType;
}

export class StoreLocation {
  constructor(
    public location?: string,
    public locationType?: StoreLocationType,
  ){}
}

export function getStoreIdentifier(store: IStore): number | undefined {
  return store.id;
}
