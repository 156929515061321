import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as dayjs from 'dayjs';

import { isPresent } from 'app/core/util/operators';
import { ApplicationConfigService } from 'app/core/config/application-config.service';
import { createRequestOption } from 'app/core/request/request-util';
import { IMagJsonParam, getMagJsonParamIdentifier } from '../mag-json-param.model';

export type EntityResponseType = HttpResponse<IMagJsonParam>;
export type EntityArrayResponseType = HttpResponse<IMagJsonParam[]>;

@Injectable({ providedIn: 'root' })
export class MagJsonParamService {
  public resourceUrl = this.applicationConfigService.getEndpointFor('api/mag-json-params');

  constructor(protected http: HttpClient, private applicationConfigService: ApplicationConfigService) {}

  create(magJsonParam: IMagJsonParam): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(magJsonParam);
    return this.http
      .post<IMagJsonParam>(this.resourceUrl, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  update(magJsonParam: IMagJsonParam): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(magJsonParam);
    return this.http
      .put<IMagJsonParam>(`${this.resourceUrl}/${getMagJsonParamIdentifier(magJsonParam) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  findByUserAndEntity(userID: string, entityID: string): Observable<EntityResponseType> {
    return this.http.get<IMagJsonParam>(`${this.resourceUrl}/find/${userID}&${entityID}`, { observe: 'response' });
  }

  partialUpdate(magJsonParam: IMagJsonParam): Observable<EntityResponseType> {
    const copy = this.convertDateFromClient(magJsonParam);
    return this.http
      .patch<IMagJsonParam>(`${this.resourceUrl}/${getMagJsonParamIdentifier(magJsonParam) as number}`, copy, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  find(id: number): Observable<EntityResponseType> {
    return this.http
      .get<IMagJsonParam>(`${this.resourceUrl}/${id}`, { observe: 'response' })
      .pipe(map((res: EntityResponseType) => this.convertDateFromServer(res)));
  }

  query(req?: any): Observable<EntityArrayResponseType> {
    const options = createRequestOption(req);
    return this.http
      .get<IMagJsonParam[]>(this.resourceUrl, { params: options, observe: 'response' })
      .pipe(map((res: EntityArrayResponseType) => this.convertDateArrayFromServer(res)));
  }

  delete(id: number): Observable<HttpResponse<{}>> {
    return this.http.delete(`${this.resourceUrl}/${id}`, { observe: 'response' });
  }

  addMagJsonParamToCollectionIfMissing(
    magJsonParamCollection: IMagJsonParam[],
    ...magJsonParamsToCheck: (IMagJsonParam | null | undefined)[]
  ): IMagJsonParam[] {
    const magJsonParams: IMagJsonParam[] = magJsonParamsToCheck.filter(isPresent);
    if (magJsonParams.length > 0) {
      const magJsonParamCollectionIdentifiers = magJsonParamCollection.map(
        magJsonParamItem => getMagJsonParamIdentifier(magJsonParamItem)!
      );
      const magJsonParamsToAdd = magJsonParams.filter(magJsonParamItem => {
        const magJsonParamIdentifier = getMagJsonParamIdentifier(magJsonParamItem);
        if (magJsonParamIdentifier == null || magJsonParamCollectionIdentifiers.includes(magJsonParamIdentifier)) {
          return false;
        }
        magJsonParamCollectionIdentifiers.push(magJsonParamIdentifier);
        return true;
      });
      return [...magJsonParamsToAdd, ...magJsonParamCollection];
    }
    return magJsonParamCollection;
  }

  protected convertDateFromClient(magJsonParam: IMagJsonParam): IMagJsonParam {
    return Object.assign({}, magJsonParam, {
      createdDate: dayjs(magJsonParam.createdDate!).isValid() ? dayjs(magJsonParam.createdDate!).toJSON() : undefined,
      lastModifiedDate: dayjs(magJsonParam.lastModifiedDate!).isValid() ? dayjs(magJsonParam.lastModifiedDate!).toJSON() : undefined,
    });
  }

  protected convertDateFromServer(res: EntityResponseType): EntityResponseType {
    if (res.body) {
      res.body.createdDate = res.body.createdDate ? dayjs(res.body.createdDate) : undefined;
      res.body.lastModifiedDate = res.body.lastModifiedDate ? dayjs(res.body.lastModifiedDate) : undefined;
    }
    return res;
  }

  protected convertDateArrayFromServer(res: EntityArrayResponseType): EntityArrayResponseType {
    if (res.body) {
      res.body.forEach((magJsonParam: IMagJsonParam) => {
        magJsonParam.createdDate = magJsonParam.createdDate ? dayjs(magJsonParam.createdDate) : undefined;
        magJsonParam.lastModifiedDate = magJsonParam.lastModifiedDate ? dayjs(magJsonParam.lastModifiedDate) : undefined;
      });
    }
    return res;
  }
}
