<div class="alerts" role="alert">
  <div *ngFor="let alert of alerts" [ngClass]="setClasses(alert)">
    <ngb-alert *ngIf="alert.message" [type]="alert.type" [dismissible]="false">
      <pre [innerHTML]="alert.message"></pre>
      <button type="button" style="float: right; margin-top: -20px;" aria-label="Close" class="btn-close" (click)="close(alert)"></button>
    </ngb-alert>
  </div>
</div>

<!-- <div class="alert alert-warning alert-dismissible fade show" role="alert">
  <strong>Holy guacamole!</strong> You should check in on some of those fields below.
  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div> -->